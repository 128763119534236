

export const FlipTextNav = () => {
    return (
        <div class="animated_text_title">
            <span >M</span>
            <span >a</span>
            <span >h</span>
            <span >m</span>
            <span >u</span>
            <span >d</span>
            <span >.</span>
        </div>
    )
}
