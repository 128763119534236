

export const ButterFly = () => {
    return (
        <div id="preloader">
            <div class="loader-list">
                <div class="loader-item">
                    <div class="loader loader-2"></div>
                </div>
            </div>
        </div>
    )
}
