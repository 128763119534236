// Libraries
import { useState, useEffect } from "react";
import TrackVisibility from "react-on-screen";

// css 
import { Container, Row, Col } from "react-bootstrap";

// img 
import hero from "../assets/img/hero.png";

// Components 
import { RoundBg } from "./animation/RoundBg";
import { AnimatedRipplebg } from "./animation/AnimatedRipplebg";
import { FlipTextBanner } from "./animation/FlipTextBanner";
import { ButterFly } from "./animation/ButterFly";
// import { Clock } from "./animation/Clock";

export const Banner = () => {
  let current_year = new Date().getFullYear();
  let experiences = current_year - 2021;

  return (
    <section className="banner" id="home">
      <AnimatedRipplebg />
      <ButterFly />
      {/* <Clock /> */}
      <Container className="">
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                >
                  <img src={hero} alt="Header Img" />
                </div>
              )}
            </TrackVisibility>
          </Col>

          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div >
                  <h2>
                    Hi, I am {'{'}
                  </h2>
                  <h2 style={{ marginLeft: "60px" }}>
                    <FlipTextBanner />
                  </h2>
                  <h2>{'}'}</h2>

                  <h3>
                    <div class="animated_text"></div>
                  </h3>
                  <p>
                    I am a <span>Full-Stack Python Developer</span> with more than <span>{experiences}+ years</span> of experiences. My track is <span>Django</span>, <span>Django Rest Framework</span>, <span>React</span>, <span>Nextjs</span>.
                  </p>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
