

export const FlipTextBanner = () => {
    return (
        <div class="animated_text_title">
            <span style={{'--i': 1}}>M</span>
            <span style={{'--i': 2}}>a</span>
            <span style={{'--i': 3}}>h</span>
            <span style={{'--i': 4}}>m</span>
            <span style={{'--i': 5}}>u</span>
            <span style={{'--i': 6}}>d</span>
            <span style={{'--i': 7}}>;</span>
        </div>
    )
}
