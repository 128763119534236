// libraries
// css 
import "react-multi-carousel/lib/styles.css";

// components
import { SkillLangCard } from "./Skill-lang-card";
import { RoundBg } from "./animation/RoundBg";

// others 
import skillsData from "./skills.json";


export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section id="skills" className="skill">
      {/* curved display  */}
      <div className="curve">
        <svg viewBox="0 0 500 60">
          <defs>
            <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{ stopColor: 'rgba(170, 54, 124, 0.6) ', stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: 'rgba(74, 47, 189, 0.6) ', stopOpacity: 1 }} />
            </linearGradient>
          </defs>
          <path d="M 0 30 C 150 100 280 0 500 20 L 500 0 L 0 0" fill="url(#grad)"></path>
        </svg>
      </div>
      <RoundBg />
      <div className="skill-bx">
        <div className="row">
          <br /><br /><br /><br /><br /><br />
          <div className=" wow zoomIn">
            <h2>Skills</h2>

          </div>
          {/* </div> */}

          {/* =======================> framework <======================= */}
          <div className="framework" >
            {/* ==========================> backend part<========================== */}
            <div className="heading">
              <h1>Backend</h1>
            </div>
            <div className="center  mb-5">
              {skillsData["backend"].map((skills, index) => {
                return <SkillLangCard key={index} {...skills} />;
              })}
            </div>

            {/* ======================> frontend <================================== */}
            <div className="heading">
              <h1>Frontend</h1>
            </div>
            <div className="center mb-5">
              {skillsData["frontend"].map((skills, index) => {
                return <SkillLangCard key={index} {...skills} />;
              })}
            </div>

            {/* ======================> Languages <================================== */}
            <RoundBg />
            <div className="heading">
              <h1>Languages</h1>
            </div>
            <div className="center mb-5">
              {skillsData["languages"].map((skills, index) => {
                return <SkillLangCard key={index} {...skills} />;
              })}
            </div>

            {/* ======================> Database <================================== */}
            <div className="heading">
              <h1>Database</h1>
            </div>
            <div className="center mb-5">
              {skillsData["db"].map((skills, index) => {
                return <SkillLangCard key={index} {...skills} />;
              })}
            </div>

            {/* ======================> API Framework <================================== */}
            <div className="heading">
              <h1>API Framework</h1>
            </div>
            <div className="center mb-5">
              {skillsData["apiFramework"].map((skills, index) => {
                return <SkillLangCard key={index} {...skills} />;
              })}
            </div>

            {/* ======================> Workspace <================================== */}
            <div className="heading">
              <h1>Workspace</h1>
            </div>
            <div className="center mb-5">
              {skillsData["workspace"].map((skills, index) => {
                return <SkillLangCard key={index} {...skills} />;
              })}
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};
